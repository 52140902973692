<script>
import AnimationBaseFunctions from "./animation-base-functions";
import AnimationEffects from "./animation-effects";

export default {
  name: "AnimationLock",
  mixins: [AnimationEffects, AnimationBaseFunctions],
  methods: {
    AnimationLockGetLock: function () {
      let html = "";
      html += '<div class="lock-part-container w-100 h-100">';
      html +=
        '<img src="' +
        require("@/assets/img/animation/lock/kingspath-animation-keylock-explosion-sprite01.png") +
        '" class="w-100 position-absolute lock-part" />';
      html +=
        '<img src="' +
        require("@/assets/img/animation/lock/kingspath-animation-keylock-explosion-sprite02.png") +
        '" class="w-100 position-absolute lock-part" />';
      html +=
        '<img src="' +
        require("@/assets/img/animation/lock/kingspath-animation-keylock-explosion-sprite03.png") +
        '" class="w-100 position-absolute lock-part" />';
      html +=
        '<img src="' +
        require("@/assets/img/animation/lock/kingspath-animation-keylock-explosion-sprite04.png") +
        '" class="w-100 position-absolute lock-part" />';
      html +=
        '<img src="' +
        require("@/assets/img/animation/lock/kingspath-animation-keylock-explosion-sprite06.png") +
        '" class="w-100 position-absolute lock-part" />';
      html += '<div class="light-effect"></div>';
      html += "</div>";
      return html;
    },

    AnimationLockAnimateLockExplosion: function (parent) {
      const timeline = this.gsap.timeline();

      let parts = parent.getElementsByClassName("lock-part");
      let light = parent.getElementsByClassName("light-effect")[0];

      let sound_exp_url = require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-metal-hit-big.mp3");
      const speed = 0.5;
      const begin_explosion = 0.1;

      //light
      timeline
        .to(
          light,
          0,
          {
            opacity: 1,
          },
          0
        )
        .to(
          light,
          0.2,
          {
            top: "80%",
            left: "49%",
            width: "5px",
            height: "5px",
          },
          0
        )
        .to(
          light,
          0.1,
          {
            opacity: 0,
          },
          0.1
        );

      //smoke
      timeline.to(
        parts[0].parentNode,
        0,
        {
          onComplete: () => {
            this.playSoundEffect(sound_exp_url, false, 0.4);

            this.AnimationEffectsAnimateSmokeExplode(
              parts[0].parentElement.offsetWidth / 2,
              parts[0].parentElement.offsetHeight / 2,
              false,
              parts[0].parentElement
            );
          },
        },
        begin_explosion
      );

      //explode
      timeline
        .to(
          parts[0],
          speed,
          {
            motionPath: [
              { x: 0, y: 0 },
              { x: 900, y: -800 },
              { x: 3000, y: 800 },
            ],
            // autoRotate: 35
            ease: "power1.inOut",
          },
          begin_explosion
        )
        .to(
          parts[1],
          speed,
          {
            motionPath: [
              { x: 0, y: 0 },
              { x: -300, y: -100 },
              { x: -3000, y: 1500 },
            ],
            ease: "power1.inOut",
          },
          begin_explosion
        )
        .to(
          parts[2],
          speed,
          {
            motionPath: [
              { x: 0, y: 0 },
              { x: 100, y: -1000 },
              { x: 120, y: -1200 },
            ],
            ease: "power1.inOut",
          },
          begin_explosion
        )
        .to(
          parts[3],
          speed,
          {
            motionPath: [
              { x: 0, y: 0 },
              { x: 300, y: -400 },
              { x: 650, y: -800 },
            ],
            ease: "power1.inOut",
          },
          begin_explosion
        )
        .to(
          parts[4],
          speed,
          {
            motionPath: [
              { x: 0, y: 0 },
              { x: -350, y: -400 },
              { x: -450, y: -800 },
            ],
            ease: "power1.inOut",
          },
          begin_explosion
        );

      //hide
      timeline.to(
        parts,
        0.1,
        {
          opacity: 0,
        },
        begin_explosion + speed - 0.1
      );
    },
  },
};
</script>

<style lang="scss">
.lock-part-container {
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
}

.light-effect {
  z-index: 19;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 30px 15px lightyellow, 0 0 50px 30px lightyellow,
    0 0 70px 45px lightyellow;
  position: absolute;
  top: 60%;
  left: 45%;
  opacity: 0;
}
</style>
