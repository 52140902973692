<template>
  <div>
    <b-modal
      id="modal-kingswheel-reward-win"
      :hide-footer="true"
      modal-class="custom-modal2"
      @shown="increaseNumber"
      @hidden="collect"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default" slot-scope="{ close }">
        <div class="headline">
          {{ $t("kingswheel.win.headline") }}
        </div>
        <div class="gold to-center-abs"></div>
        <div class="win-container to-center-abs">
          <div ref="amount" class="amount">0</div>
          <img
            draggable="false"
            class="coin-icon"
            src="@/assets/img/common/coin1.png"
          />
        </div>
        <div class="button-container to-center-abs">
          <DecoButton color-variant="green" @click="close">
            {{ $t("kingswheel.win.button") }}
          </DecoButton>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DecoButton from "@/components/base/deco-button.vue";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import AnimationNumberIncrease from "@/components/animation/animation-number-increase";

export default {
  name: "ModalKingswheelRewardWin",
  components: {
    DecoButton,
  },
  mixins: [AnimationFullRewardEarned, AnimationNumberIncrease],
  props: { amount: { type: Number, default: 0 } },
  mounted: async function () {
    // this.$bvModal.show("modal-kingswheel-reward-win");
  },

  methods: {
    winSound: function () {
      let win_sound = require("@/assets/sound/kingspath/win2.wav");
      this.playSoundEffect(win_sound, false, 0.4);
    },
    increaseNumber: function () {
      this.winSound();

      let sound_counter = require("@/assets/sound/animation/full-reward-earned/counter.mp3");
      const element = this.$refs["amount"];

      this.AnimationNumberIncreaseAnimate(
        element,
        1000,
        this.amount,
        0.5,
        "",
        sound_counter
      );
    },
    collect: function () {
      const result = [];
      result.push({
        reward: "gold",
        amount: this.formatNumber(this.amount),
      });
      this.AnimationFullRewardEarnedAnimate(result, false);
    },
  },
};
</script>

<style lang="scss">
#modal-kingswheel-reward-win {
  $modal-width: 1067px;
  $modal-height: 619px;

  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -80px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }

  .close-button {
    right: -15px;
    top: -15px;
    width: 54px;
    height: 54px;
    max-width: none;
  }
  .deco-button {
    width: 369px !important;
    height: 73px !important;
    font-family: Ubuntu;
    font-size: 43.5px;

    .deco-gem {
      width: 35px;
      height: 86px;
      top: -8px;

      &.left {
        left: -18px;
      }
      &.right {
        right: -18px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
#modal-kingswheel-reward-win {
  .headline {
    font-family: Ubuntu-Bold;
    font-size: 96px;
    color: white;
    text-shadow: 8px 8px 8px rgba(77, 229, 255, 0.3),
      -8px -8px 8px rgba(77, 229, 255, 0.3),
      -8px 10px 8px rgba(77, 229, 255, 0.3),
      10px -10px 8px rgba(77, 229, 255, 0.3);
    text-transform: uppercase;
  }

  .gold {
    top: 140px;
    z-index: 10;
    width: 329px;
    height: 213px;
    background: url(~@/assets/img/friends/fellowship-gold.png) center/100% 100%
      no-repeat;
  }

  .win-container {
    top: 340px;
    width: 880px;
    height: 160px;
    border-radius: 80px;
    border: 4px solid #4de5ff;
    background: linear-gradient(to top, #12212b, #020203) center/100% 100%
      no-repeat;
    text-align: center;

    .amount {
      font-family: Ubuntu-Bold;
      font-size: 96px;
      color: white;
      text-shadow: 8px 8px 8px rgba(77, 229, 255, 0.3),
        -8px -8px 8px rgba(77, 229, 255, 0.3),
        -8px 10px 8px rgba(77, 229, 255, 0.3),
        10px -10px 8px rgba(77, 229, 255, 0.3);
      display: inline;
    }

    img {
      width: 88px;
      margin-top: -55px;
      margin-left: 20px;
      display: inline;
    }
  }

  .button-container {
    top: 550px;
  }
}
</style>
