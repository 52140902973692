import store from "@/store/store.js";
import { optionsFunction } from "./_config";
const axios = require("axios");

export const fetchKingspathOverview = () =>
  axios
    .get(`/api/kingspath/overview`, optionsFunction())
    .then((res) => {
      store.commit("kingspath/setKingspathNeedUpdate", false);
      return res;
    })
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const unlockKingspathMission = () =>
  axios
    .post(`/api/kingspath/unlock`, {}, optionsFunction())
    .then((res) => res.data.data);

export const activateKingspathMission = (mission_id) =>
  axios
    .post(
      `/api/kingspath/activate`,
      {
        user_mission_id: mission_id,
      },
      optionsFunction()
    )
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchKingspathWheel = () =>
  axios
    .get(`/api/kingspath/wheel`, optionsFunction())
    .then((res) => {
      store.commit("kingspath/setKingwheelNeedUpdate", false);
      return res;
    })
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const spinKingspathWheel = () =>
  axios
    .post(`/api/kingspath/wheel/spin`, {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
