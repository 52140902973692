<template>
  <div id="kingspath-page" class="container-fluid p-0">
    <kingspath-map></kingspath-map>
    <modal-kingswheel></modal-kingswheel>
  </div>
</template>

<script>
import KingspathMap from "@/components/kingspath/kingspath-map";
import ModalKingswheel from "@/components/kingspath/modal-kingswheel";

export default {
  name: "KingspathPage",
  components: {
    ModalKingswheel,
    KingspathMap,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
