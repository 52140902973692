<template>
  <div>
    <b-modal
      id="modal-kingspath-friends"
      :hide-footer="true"
      modal-class="custom-modal2"
      content-class="rectangular-dark-blue-modal"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
        <div class="title-icon to-center-abs"></div>
      </template>

      <template slot="default">
        <div v-if="friendData" class="content">
          <div class="title yellow-text">
            {{ $t("kingspath.friends.title") }}
          </div>
          <b-row id="kingspath-friends-row">
            <b-col cols="12" class="custom-table-head"> </b-col>
            <b-col cols="12" class="table-col">
              <b-table
                id="kingspath-friends-table"
                small
                sort-by="level"
                sort-desc
                :items="friendData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                thead-class="head-row-text"
                @row-clicked="openDetailsModal"
              >
                <template #head()="data">{{ $t(data.label) }}</template>
              </b-table>
            </b-col></b-row
          >
          <tablePagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="friendData.length"
          />
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import tablePagination from "@/components/base/pagination.vue";
export default {
  name: "ModalKingspathFriends",
  components: { tablePagination },
  mixins: [getPublicUserInfos],
  props: { friendData: { type: Array, default: null } },
  data() {
    return {
      currentPage: 1,
      perPage: 7,
      fields: [
        {
          key: "name",
          label: "friends.friends.table_title.name",
          sortable: true,
        },
        {
          key: "level",
          label: "friends.friends.table_title.level",
          sortable: true,
        },
      ],
    };
  },

  mounted() {
    // this.$bvModal.show("modal-kingspath-friends");
  },
  methods: {
    openDetailsModal(row) {
      if (row.deleted_at) return;
      this.fetchPublicUser(row.id);
    },
  },
};
</script>

<style lang="scss">
#modal-kingspath-friends {
  $modal-width: 542px;
  $modal-height: 611px;

  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 70px;

    @media (max-height: 700px) {
      transform: scale(0.85);
      top: -80px;
      margin-bottom: -15%;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }

  .close-button {
    right: -25px;
    top: -25px;
    width: 54px;
    height: 54px;
    max-width: none;
  }
}
</style>
<style scoped lang="scss">
#modal-kingspath-friends {
  .title-icon {
    top: -40px;
    width: 88px;
    height: 88px;
    background: url(~@/assets/img/kingspath/map/kleinekrone-kingspath-ic-ownstate-friends-big.png)
      center/100% 100% no-repeat;
    filter: drop-shadow(0px 3px 6px rgb(77, 229, 255));
  }
  .content {
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    margin-top: 10px;
    font-family: Cinzel-Bold;
    font-size: 32px;
    text-align: center;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ */
      & {
        background: transparent;
        color: rgb(253, 242, 141);
      }
    }
  }

  #kingspath-friends-row {
    margin-top: 20px;
    padding: 0 20px;
  }
  .pagination {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 20px;
  }
}
</style>
