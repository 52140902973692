<template>
  <div class="kingspath-card">
    <Spinner
      v-if="loading"
      size="medium"
      context="Loading Event..."
      classes="dead-center"
    ></Spinner>
    <div class="flip-card h-100" :class="{ flipped: mission }">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <template v-if="opened">
            <div class="card-locked-img" v-html="lock"></div>
          </template>
          <img
            v-if="index % 2 === 0"
            draggable="false"
            src="@/assets/img/kingspath/choose-mission/cardRedNew.png"
            class="w-100"
          />
          <img
            v-else
            draggable="false"
            src="@/assets/img/kingspath/choose-mission/cardBlueNew.png"
            class="w-100"
          />
        </div>
        <div class="flip-card-back">
          <template v-if="mission">
            <div class="progress-bar to-center-abs">
              <div
                class="progress-fill"
                :style="{ width: mission.progress.percentage + '%' }"
              ></div>
              <div class="game-progress-text to-center-abs">
                {{ Math.round(mission.progress.percentage) }}%
              </div>
            </div>
            <div class="kingspath-card-open">
              <img
                draggable="false"
                src="@/assets/img/kingspath/choose-mission/cardBacksideNew.png"
                class="w-100"
              />
              <div
                v-if="mission.mission"
                class="kingspath-card-open-game-img to-center-abs"
              >
                <div
                  class="thumbnail"
                  :style="{ backgroundImage: 'url(' + thumbnail + ')' }"
                ></div>
              </div>

              <div class="kingspath-card-open-text1 to-center-abs">
                {{ showMission(mission) }}
              </div>

              <div class="kingspath-card-open-difficulty to-center-abs">
                <div
                  class="kingspath-card-open-difficulty-text to-center-abs text-normal"
                  :class="[
                    { staticClass: true },
                    mission.difficulty.toLowerCase(),
                  ]"
                >
                  {{
                    $t(
                      "kingspath.quest.difficulty." +
                        mission.difficulty.toLowerCase()
                    ).toUpperCase()
                  }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationLock from "../animation/animation-lock";
import Spinner from "@/components/animation/animation-spinner.vue";

export default {
  name: "KingspathCard",
  components: {
    Spinner,
  },
  mixins: [AnimationLock],
  props: {
    mission: { type: Object, default: null },
    opened: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    config: { type: Object, default: null },
  },

  computed: {
    spins: function () {
      if (this.config === null || this.config === undefined) {
        return "";
      }

      return this.config["kingspath.mission.spin.bet.repeats"];
    },
    lock: function () {
      return this.AnimationLockGetLock();
    },
    thumbnail: function () {
      return this.mission.game_details.thumbnail;
    },
  },

  methods: {
    showMission: function (card) {
      let val = Math.round(card.target);
      let type = card.mission.type;
      let spin_repeat = this.spins;

      val = this.formatNumber(val);

      switch (type) {
        case "WIN_ONCE":
          return this.$t("kingspath.quest.type.win_once", { 0: val });
        case "SPIN_TOTAL":
          return this.$t("kingspath.quest.type.spin_total", { 0: val });
        case "SPIN_BET_TOTAL":
          return this.$t("kingspath.quest.type.spin_bet_total", {
            0: spin_repeat,
            1: val,
          });
        case "WIN_TOTAL":
          return this.$t("kingspath.quest.type.win_total", { 0: val });
        default:
          return this.$t("kingspath.quest.type.win_once", { 0: val });
      }
    },
  },
};
</script>

<style lang="scss">
.kingspath-card {
  .smoke-explode {
    margin-left: 30px;
    margin-top: 30px;
  }

  .spinner-container {
    left: 50px;
  }
}
</style>

<style scoped lang="scss">
.kingspath-card {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100%;
  width: 100%;

  .card-locked-img {
    position: absolute;
    bottom: 85px;
    right: 16px;
    width: 75px;
  }

  .card-flipper {
    transform-style: preserve-3d;
    transition: 0.5s;
  }

  .card-flipper.flip {
    transform: rotateY(180deg);
  }

  .card-flipper.flip .front {
    z-index: 1;
  }

  .face {
    position: absolute;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .front {
    z-index: 10;
  }

  .back {
    transform: rotateY(180deg);
  }
}

.progress-bar {
  width: 182px;
  height: 42px;
  top: -16px;
  z-index: 20;
  overflow: hidden;
  outline: 10px solid transparent;
  border-radius: 16px;
  border: double 1px transparent;
  background: url(~@/assets/img/kingspath/choose-mission/overlay-startbattle-display-gamename-back.png)
    no-repeat;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: 100% 100%;
  .progress-fill {
    height: 42px;
    max-width: 100%;
    background-color: #4de5ff;
    mask-image: url(~@/assets/img/kingspath/map/kleinekrone-kingspath-ic-slotstation-progress-outer.png);
    mask-repeat: no-repeat;
    mask-size: 158px 18px;
    mask-position: 11px 8px;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .game-progress-text {
    font-family: Ubuntu-Bold;
    top: 7px;
    right: -110px;
    width: 120px;
    line-height: 1.2;
    z-index: 1;
    font-size: 17px;
  }
}

.kingspath-card-open {
  position: relative;

  .kingspath-card-open-game-img {
    width: 84%;
    height: 39%;
    left: -1%;
    top: 30px;
    background-image: linear-gradient(to bottom, #f7ffff, #ffee51, #8b5a00),
      linear-gradient(to bottom, #f7ffff, #ffee51, #8b5a00);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border: double 2px transparent;
    padding: 1px;

    .thumbnail {
      width: 99%;
      height: 99%;
      background-repeat: no-repeat;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-size: cover;
      background-position: center;
    }
  }

  .kingspath-card-open-text1 {
    font-family: Ubuntu-Bold;
    color: #613307;
    font-size: 18px;
    top: 155px;
    width: 90%;
    line-height: 1.2;
  }

  .kingspath-card-open-difficulty {
    position: relative;
    margin-top: -17.5%;
  }

  .kingspath-card-open-difficulty-text {
    top: -6px;
    width: 104px;
    height: 28px;
    padding-top: 1px;
    background: url(~@/assets/img/kingspath/choose-mission/kp-bg-card-grade.png)
      no-repeat;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
    background-position: center;

    &.easy {
      color: #43ff00;
      text-shadow: 1px 1px 1px rgba(67, 255, 0, 0.68),
        0 0 25px rgba(67, 255, 0, 0.68), 0 0 1px rgba(67, 255, 0, 0.68);
    }

    &.normal {
      color: #ffa900;
      text-shadow: 1px 1px 1px rgba(255, 169, 0, 0.6),
        0 0 25px rgba(255, 169, 0, 0.6), 0 0 1px rgba(255, 169, 0, 0.6);
    }

    &.hard {
      color: #ff0000;
      text-shadow: 1px 1px 1px rgba(255, 0, 0, 0.5),
        0 0 25px rgba(255, 0, 0, 0.5), 0 0 1px rgba(255, 0, 0, 0.5);
    }

    &.extreme {
      color: #ff0000;
      text-shadow: 1px 1px 1px rgba(255, 0, 0, 0.5),
        0 0 25px rgba(255, 0, 0, 0.5), 0 0 1px rgba(255, 0, 0, 0.5);
    }
  }
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);

  .card-locked-img {
    display: none;
  }
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}
</style>
