<template>
  <div>
    <b-modal
      id="modal-kingspath-choose-mission"
      class="custom-modal3"
      :hide-footer="true"
      modal-class="custom-modal1"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default">
        <div class="container-fluid modal-container">
          <div class="row">
            <div v-if="missionData.current" class="header">
              <div class="sub1 to-center-abs">
                {{ $t("kingspath.map.quest.level") }}
              </div>

              <div class="sub2 to-center-abs">
                {{ parseInt(missionData.current.level) + 1 }}
              </div>
            </div>
            <div class="modal-text-sub">
              {{ $t("kingspath.popup_choose_quest.sub_title") }}
            </div>
          </div>

          <div class="row card-container">
            <template v-for="(card, index) in 4">
              <div
                :key="'mission_' + index"
                class="col-md-3"
                :class="'card-' + index"
                @click="currentChosenCard = index"
              >
                <div class="mission-container">
                  <KingspathCard
                    :mission="missions[index]"
                    :opened="opened"
                    :index="index"
                    :config="config"
                    :loading="loading && index === currentChosenCard"
                  ></KingspathCard>
                </div>
                <KingspathCardButton
                  :mission="missions[index]"
                  :opened="opened"
                  :index="index"
                  :config="config"
                  @activate-mission="activateMission"
                  @unlock-mission="unlockMission"
                ></KingspathCardButton>
              </div>
            </template>
          </div>
        </div>
      </template>
    </b-modal>
    <modal-kingspath-buy-mission
      :amount="config['kingspath.buy.costs']"
      :index="currentChosenCard"
      @handle-unlock-mission="unlockMission"
    ></modal-kingspath-buy-mission>
  </div>
</template>

<script>
import * as kingspathAPI from "@/API/kingspath.js";
import Vue from "vue";
import KingspathCard from "./kingspath-card";
import KingspathCardButton from "./kingspath-card-button";
import AnimationLock from "@/components/animation/animation-lock";
import ModalKingspathBuyMission from "@/components/kingspath/modal-kingspath-buy-mission";

export default {
  name: "ModalKingspathChooseMission",
  components: {
    ModalKingspathBuyMission,
    KingspathCard,
    KingspathCardButton,
  },
  mixins: [AnimationLock],
  props: { overviewData: { type: Object, default: null } },
  data() {
    return {
      missionData: [],
      missions: [],
      loading: false,
      currentChosenCard: null,
    };
  },

  computed: {
    opened: function () {
      let opened = false;
      const card_amount = 4;
      for (let i = 0; i < card_amount; i++) {
        if (this.missions[i] === undefined || this.missions[i].id === null) {
          continue;
        }
        opened = true;
      }

      return opened;
    },

    config: function () {
      return this.$store.getters["getGeneralConfig"].settings;
    },
  },

  mounted: async function () {
    this.missionData = this.overviewData;
    this.missions = this.overviewData.current.missions;
  },
  updated: function () {},

  methods: {
    activateMission: async function (mission) {
      const mission_id = mission.id;
      const game_id = mission.game_id;

      this.playSoundTab();

      await kingspathAPI
        .activateKingspathMission(mission_id)
        .then(() => {
          this.playGame(game_id);
        })
        .catch((err) => console.log(err));
    },

    unlockMission: async function (index) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.playSoundTab();

      let lock = document.getElementsByClassName("card-locked-img")[index];
      let card = document.getElementsByClassName("flip-card-inner")[index];
      const timeline = this.gsap.timeline({
        defaults: {
          transformPerspective: 1000,
        },
      });

      kingspathAPI
        .unlockKingspathMission()
        .then((res) => {
          //lock explosion
          if (lock) {
            this.firebaseVirtualCurrencyDrain(
              "add_kingspath_task",
              "CROWN",
              this.config["kingspath.buy.costs"],
              "KINGS_PATH"
            );

            timeline.to(
              card,
              0.01,
              {
                onStart: () => {
                  this.$bvModal.hide("modal-kingspath-buy-mission");
                },
              },
              0
            );
            timeline.to(
              card,
              0.01,
              {
                onStart: () => {
                  this.AnimationLockAnimateLockExplosion(lock);
                },
              },
              0.5
            );
          }

          //card flip
          timeline.to(
            card,
            0.5,
            {
              onStart: () => {
                res.mission.flipped = true;
                Vue.set(this.missions, index, res.mission);
                this.playSoundCardFlip();
              },
              rotationY: 180,
              ease: "power0.inOut",
            },
            1.2
          );

          //update map
          timeline.to(
            card,
            0.5,
            {
              onComplete: () => {
                this.$store.commit("kingspath/setKingspathNeedUpdate", true);
                this.loading = false;
              },
            },
            1.7
          );
        })
        .catch((e) => {
          if (e.data.id === "general.error.not_enough_currency") {
            this.$bvModal.hide("modal-kingspath-buy-mission");
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t("mission.your_chest.crowns_insufficient"),
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
          }
        });
    },

    playGame: function (game_id) {
      this.playSoundButtonCommon();

      // add fallback game in case game id is null or undefined
      if (!game_id) {
        this.$router.push("/game/3");
      }
      this.$router.push(`/kingspath/game/${game_id}`);
    },
  },
};
</script>

<style lang="scss">
#modal-kingspath-choose-mission {
  $modal-width: 1067px;
  $modal-height: 619px;

  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;

    @media (max-height: 700px) {
      transform: scale(0.9);
      top: -40px;
      margin-bottom: -10%;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/kingspath/choose-mission/kpBgSelectCard.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .close-button {
    position: absolute;
    right: -15px;
    top: -15px;
    width: 54px;
    height: 54px;
    max-width: none;
    background: url(~@/assets/img/mission/mission-close-button.png) no-repeat;
    background-size: 100% 100%;

    &:hover {
      background-image: url(~@/assets/img/mission/mission-close-button-hover.png);
    }
  }
}
</style>
<style scoped lang="scss">
#modal-kingspath-choose-mission {
  .modal-text-sub {
    position: absolute;
    left: 100px;
    bottom: 55px;
    font-family: cinzel-decorative-bold;
    font-size: 32px;
    color: #fad5a8;
  }

  .modal-container {
    width: 93%;
  }

  .header {
    text-transform: uppercase;
    font-size: 51px;
    color: #4a2101;

    .sub1 {
      top: 0;
      left: -130px;
      font-family: Cinzel-Bold;
    }
    .sub2 {
      top: 0;
      left: 275px;
      font-family: Ubuntu;
      text-align: center;
    }
  }

  .card-container {
    margin-top: 80px;

    .card-0 {
      margin-top: 0;
    }
    .card-1 {
      margin-top: 35px;
    }
    .card-2 {
      margin-top: 140px;
    }
    .card-3 {
      margin-top: 110px;
    }

    .mission-container {
      width: 196px;
      height: 270px;
      margin-left: auto;
      margin-right: auto;
    }

    .game-progress-bg {
      top: -10px;
      width: 70px;
      z-index: 1;
    }

    .game-progress-text {
      font-family: Ubuntu-Bold;
      top: -1px;
      width: 120px;
      line-height: 1.2;
      z-index: 1;
      font-size: 20px;
    }
  }
}
</style>
