<template>
  <div>
    <b-modal
      id="modal-kingswheel"
      class="custom-modal3"
      :hide-footer="true"
      modal-class="custom-modal1"
      @shown="onOpen"
      @hidden="onClose"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <div class="preload"></div>
        <a href="#" class="close-button" @click="close()"> </a>
        <div class="headline to-center-abs">
          <img
            :src="
              require(`@/assets/img/kingspath/rad/kp-wheel-hl-${$store.state.locale}.png`)
            "
            class="w-100"
          />
        </div>
        <Spinner
          v-if="loading"
          size="medium"
          context="Loading Kingwheel..."
          classes="dead-center kingwheel-spinner"
        ></Spinner>
      </template>

      <template slot="default">
        <div id="kingswheel-container" class="w-100 h-100 position-relative">
          <div v-if="jackpotSize > 0" id="jackpot-gold">
            <div class="coin-container to-center-abs">
              <span class="coin-text">
                {{ formatNumber(jackpotSize) }}
              </span>
              <img
                draggable="false"
                class="coin-icon"
                src="@/assets/img/common/coin1.png"
              />
            </div>
          </div>

          <div id="pixi-kingswheel"></div>

          <div class="infotext">
            <div class="info-headline">
              {{ $t("kingswheel.info.title") }}
              <img
                draggable="false"
                src="@/assets/img/kingspath/rad/element-13-6-x-clean.png"
              />
            </div>
            <div class="text1">
              {{ $t("kingswheel.info.message") }}
            </div>
            <div class="text2">
              {{ $t("kingswheel.info.message2") }}
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <modal-kingswheel-reward-win
      :amount="winAmount"
    ></modal-kingswheel-reward-win>
  </div>
</template>

<script>
import * as PIXI from "pixi.js-legacy";
import * as kingspathAPI from "@/API/kingspath.js";
import Spinner from "@/components/animation/animation-spinner";
import url_font_kanit_white from "@/assets/fonts/kingspath/kanit-semibold-white.fnt";
import url_img_kanit_white from "@/assets/fonts/kingspath/kanit-semibold-white_nohash.png";
import url_font_kanit_black from "@/assets/fonts/kingspath/kanit-semibold-black.fnt";
import url_img_kanit_black from "@/assets/fonts/kingspath/kanit-semibold-black_nohash.png";
import ModalKingswheelRewardWin from "@/components/kingspath/modal-kingswheel-reward-win";

export default {
  name: "ModalKingswheel",
  components: { ModalKingswheelRewardWin, Spinner },
  data() {
    return {
      wheelData: null,
      lastSpin: [],
      unsubscribeEvents: [],
      loading: true,
      destroyApp: false,
      winAmount: 100000,
    };
  },
  computed: {
    currentUser: function () {
      return this.$store.getters["user/currentUser"];
    },
    jackpotSize: function () {
      if (!this.wheelData) {
        return 0;
      }
      return this.wheelData.config[3].fields[0].value;
    },
  },

  mounted: function () {
    // this.$bvModal.show("modal-kingswheel");
  },
  destroyed: function () {
    this.destroyApp = true;
  },

  methods: {
    onOpen() {
      this.loading = true;

      this.destroyApp = false;

      this.playBackgroundKingswheel();

      this.$store.dispatch("user/setDelayedUpdate", true);
      kingspathAPI
        .fetchKingspathWheel()
        .then((res) => {
          this.wheelData = res;
          this.createWheel();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onClose() {
      try {
        const container = document.getElementById("pixi-kingswheel-canvas");
        if (container) {
          container.remove();
        }

        this.stopAllSoundsExceptBackground();
        this.playBackgroundMusic();
        this.destroyApp = true;
        this.loading = true;
      } catch (e) {
        console.log(e);
        this.destroyApp = true;
      }
    },
    // BEGIN CREATE WHEEL
    createWheel: function () {
      if (!document.getElementById("pixi-kingswheel")) {
        //modal was probably closed right after opening
        console.log("closed Kingwheel");
        return;
      }

      const this_canvas = document
        .getElementById("pixi-kingswheel")
        .getElementsByTagName("canvas")[0];
      if (this_canvas) {
        console.log("already created. close");
        return;
      }

      let testing = false; //if true, nothing will be send to api
      if (process.env.NODE_ENV !== "production") {
        if (this.currentUser.id === 2 || this.currentUser.id === 15) {
          testing = true;
        }
      }

      let testlevel = 0;
      let testcounter = 0;
      let test_json1 = JSON.parse('{"1": {"field_id": 16,"value": 2000}}');
      let test_json2 = JSON.parse(
        '{"1": {"field_id": 16,"value": null},"2": {"field_id": 28,"value": 5000000}}'
      );
      let test_json3 = JSON.parse(
        '{"1": {"field_id": 16,"value": null},"2": {"field_id": 19,"value": 5000000},"3": {"field_id": 36,"value": 1000000}}'
      );

      //globals
      let api_result_last_spin = this.wheelData.current.data;
      let wheelCurrentPosition; //current position (in dregree) of running wheel
      let currentSpeed; //current speed of wheel
      let currentSpeedBase = 10; //current speed of wheel
      let current_wheel; //reference of current wheel/ring
      let current_wheel_level; //the current level of the wheel. 1=outer ring, 2=middle ring, 3=outer ring
      let current_wheel_var; //field contents of current ring
      let past_field_counter; //counts the fields of previous rings.
      let automatic_restart = false;
      let isNextSpinAvailable = true;
      let nextWheelLevel;
      let calcWinnerAmount = 0; //win amount
      let jackpotAmount = this.wheelData.config[3].fields[0].value; //amount of jackpot
      let nextHoldpoint; //the calculated final hold point
      let isRunning = false;
      let stopPressed = false;
      let automaticStopDate;
      let frameSeconds = 0;

      //data from api
      let var_ring_outer = this.wheelData.config[0].fields;
      let var_ring_middle = this.wheelData.config[1].fields;
      let var_ring_inner = this.wheelData.config[2].fields;

      //Containers
      let container_ring_outer, container_ring_middle, container_ring_inner;

      //reset loader and textures
      let loader = PIXI.Loader.shared;
      loader.reset();
      PIXI.utils.clearTextureCache();

      //Aliases
      let Application = PIXI.Application,
        resources = loader.resources,
        Sprite = PIXI.Sprite;

      //Sprites
      let ring_outer, ring_middle, ring_inner, spin_button, state, wheel_icon;
      let arrows = [];

      //urls
      const url_ring_border = require("@/assets/img/kingspath/rad/element-22-6-x-copy@3x.png");
      const url_ring_outer = require("@/assets/img/kingspath/rad/element346X@3x.png");
      const url_ring_middle = require("@/assets/img/kingspath/rad/element336X@3x.png");
      const url_ring_inner = require("@/assets/img/kingspath/rad/element326X@3x.png");
      const url_ring_jack = require("@/assets/img/kingspath/rad/element-12-6-x-copy@3x.png");
      const url_ring_arrow = require("@/assets/img/kingspath/rad/element-13-6-x@3x.png");
      const url_stopper = require("@/assets/img/kingspath/rad/element-14-6-x-copy@3x.png");
      const url_spin_ring_outer = require("@/assets/img/kingspath/rad/element256X@3x.png");
      const url_spin_ring_middle = require("@/assets/img/kingspath/rad/element246X@3x.png");
      const url_spin_ring_inner = require("@/assets/img/kingspath/rad/element236X@3x.png");
      const url_button_green1 = require("@/assets/img/kingspath/rad/kp-button-rotator-green-1.png");
      const url_button_green2 = require("@/assets/img/kingspath/rad/kp-button-rotator-green-2.png");
      const url_button_red1 = require("@/assets/img/kingspath/rad/kp-button-rotator-red-1.png");
      const url_button_red2 = require("@/assets/img/kingspath/rad/kp-button-rotator-red-2.png");
      const url_button_arrow_green = require("@/assets/img/kingspath/rad/kp-btn-ani-arrow-green.png");
      const url_button_arrow_red = require("@/assets/img/kingspath/rad/kp-btn-ani-arrow-red.png");
      const url_wheel_icon = require("@/assets/img/kingspath/rad/kp-btn-inner-spiral.png");
      const url_win_frame = require("@/assets/img/kingspath/rad/kp-wheel-winframe.png");

      const urls = [
        url_ring_border,
        url_ring_outer,
        url_ring_middle,
        url_ring_inner,
        url_ring_jack,
        url_ring_arrow,
        url_spin_ring_outer,
        url_spin_ring_middle,
        url_spin_ring_inner,
        url_stopper,
        url_button_green1,
        url_button_green2,
        url_button_red1,
        url_button_red2,
        url_button_arrow_green,
        url_button_arrow_red,
        url_wheel_icon,
        url_win_frame,
      ];

      //sounds
      const sound_start = require("@/assets/sound/kingspath/1-start.mp3");
      const sound_main = require("@/assets/sound/kingspath/2-mainspinloop.wav");
      const sound_end = require("@/assets/sound/kingspath/3-ending.mp3");

      //base width and height of canvas
      const WIDTH = 1100;
      const HEIGHT = 550;

      //Create a Pixi Application
      let app = new Application({
        width: WIDTH,
        height: HEIGHT,
        backgroundAlpha: 0,
        autoResize: true,
      });
      app.view.id = "pixi-kingswheel-canvas";

      //add the canvas to element
      const container = document.getElementById("pixi-kingswheel");
      container.appendChild(app.view);

      //load assets and do setuo
      loader
        .add(urls)
        .add("kanitblack", url_font_kanit_black)
        .add("imgkanitblack", url_img_kanit_black)
        .add("kanitwhite", url_font_kanit_white)
        .add("imgkanitwhite", url_img_kanit_white)
        .load(() => {
          setup();
        });

      //assets are loaded. do all the stuff here
      const setup = () => {
        getCurrentWheelData();
        createSprites();

        //Set the game state
        state = stateReady;

        //Start the game loop
        app.ticker.fps = 60;
        app.ticker.add((delta) => gameLoop(delta));
        animateArrows();

        this.loading = false;
      };

      const gameLoop = (delta) => {
        if (this.destroyApp === true) {
          destroyAll();
          return;
        }

        //update the button to green/grey/red depending on the situation
        updateButton(delta);

        //animate small wheel icon
        wheel_icon.angle++;

        if (this.handleNeedsUpdate()) {
          app.ticker.addOnce((delta) => restart(delta));
        }

        state(delta);
      };

      //click event of button
      const clickWheelStart = () => {
        if (isRunning) {
          if (!stopPressed) {
            this.playSoundButtonCommon();
            stopPressed = true;
          }
          return;
        }
        if (isNextSpinAvailable) {
          this.playSoundButtonCommon();
          play();
        }
      };

      const play = () => {
        if (state !== stateReady) {
          return;
        }

        isRunning = true;

        //modal closed?
        const this_modal = document.getElementById("pixi-kingswheel");
        if (!this_modal) {
          state = statePrepareEnd;
          return;
        }

        getCurrentWheelData(() => {
          //reset setting and start wheel
          resetSettings(() => {
            app.ticker.add(animationWheelRotation);
            this.playSoundEffect(sound_start, false, 0.5);
            this.playSoundEffect(sound_main, true, 0.5);
            state = stateWaiting;
          });

          if (testing) {
            //with test data
            setTimeout(() => {
              testcounter++;

              isNextSpinAvailable = false;
              if (testcounter <= testlevel && testlevel < 4) {
                isNextSpinAvailable = true;
                automatic_restart = true;
              }

              switch (testcounter) {
                case 1:
                  api_result_last_spin = test_json1;
                  break;
                case 2:
                  api_result_last_spin = test_json2;
                  break;
                case 3:
                  api_result_last_spin = test_json3;
                  break;
              }
            }, 1000);
          } else {
            // real api call
            this.handleWheelSpin()
              .then((res) => {
                api_result_last_spin = res;
              })
              .then(() => {
                this.handleIsNextMoveAvailable().then((res) => {
                  isNextSpinAvailable = res;
                });
              });
          }
        });
      };

      //default state
      const stateReady = () => {
        if (!isNextSpinAvailable) {
          state = statePrepareEnd;
        }

        app.ticker.remove(animationWheelRotation);

        if (automatic_restart) {
          automatic_restart = false;
          app.ticker.addOnce(play);
        }
      };

      //rotate the wheel
      const animationWheelRotation = () => {
        const wheel = current_wheel();

        //move wheel
        wheel.angle += wheel.direction * currentSpeed;
        wheel.angle = wheel.angle % 360;

        //save current position
        wheelCurrentPosition = wheel.angle;
        wheelCurrentPosition = Math.round(wheelCurrentPosition);

        //automatic stop after x seconds
        const now = new Date().getTime();
        if (now > automaticStopDate) {
          stopPressed = true;
        }
      };

      //Update text of button based on current state
      const updateButton = (delta) => {
        //animate buttons
        frameSeconds += (1 / 60) * delta;
        if (frameSeconds >= 0.5) {
          frameSeconds = 0;
          animateButton();
        }

        //change to red button
        if (isRunning) {
          spin_button.interactive = true;
          spin_button.buttonMode = true;

          if (
            spin_button.name !== url_button_red1 &&
            spin_button.name !== url_button_red2
          ) {
            changeSpriteTexture(spin_button, url_button_red1);
            for (let i = 0; i < arrows.length; i++) {
              changeSpriteTexture(arrows[i], url_button_arrow_red);
            }
            spin_button.children[0].text = this.$t(
              "kingswheel.button.stop"
            ).toUpperCase();
          }
          return;
        }

        //change to green button
        spin_button.interactive = true;
        spin_button.buttonMode = true;
        if (
          spin_button.name !== url_button_green1 &&
          spin_button.name !== url_button_green2
        ) {
          changeSpriteTexture(spin_button, url_button_green1);
          for (let i = 0; i < arrows.length; i++) {
            changeSpriteTexture(arrows[i], url_button_arrow_green);
          }
        }
      };

      const changeSpriteTexture = (sprite_obj, texture_url) => {
        sprite_obj.texture = resources[texture_url].texture;
        sprite_obj.name = texture_url;
        sprite_obj.height = sprite_obj.texture.height;
        sprite_obj.width = sprite_obj.texture.width;
      };

      const animateArrows = () => {
        for (let i = 0; i < arrows.length; i++) {
          const speed = 0.33;
          const timeline = this.gsap.timeline({ delay: i * speed });

          timeline.fromTo(
            arrows[i],
            speed,
            { alpha: 0 },
            {
              alpha: 1,
            },
            0
          );
          timeline.to(
            arrows[i],
            speed,
            {
              alpha: 0,
            },
            speed
          );
          timeline.to(
            arrows[0],
            0.01,
            {
              onStart: function () {
                timeline.restart();
              },
            },
            speed * 3
          );
        }
      };

      const animateButton = () => {
        switch (spin_button.name) {
          case url_button_red1:
            changeSpriteTexture(spin_button, url_button_red2);
            break;
          case url_button_red2:
            changeSpriteTexture(spin_button, url_button_red1);
            break;
          case url_button_green1:
            changeSpriteTexture(spin_button, url_button_green2);
            break;
          case url_button_green2:
            changeSpriteTexture(spin_button, url_button_green1);
            break;
          default:
            changeSpriteTexture(spin_button, url_button_green1);
        }
      };

      const restart = () => {
        destroyAll();
        this.$store.commit("kingspath/setKingwheelNeedUpdate", true);
      };

      const destroyAll = () => {
        this.destroyApp = false;

        while (app.stage.children[0]) {
          app.stage.children[0].destroy(true);
        }

        while (container_ring_outer.children[0]) {
          container_ring_outer.children[0].destroy(true);
        }

        while (container_ring_middle.children[0]) {
          container_ring_middle.children[0].destroy(true);
        }

        while (container_ring_inner.children[0]) {
          container_ring_inner.children[0].destroy(true);
        }

        app.loader.reset();
        app.loader.destroy(true);
        loader.reset();
        loader.destroy(true);

        //destroy canvas context
        const contextIds = ["bitmaprenderer", "2d", "webgl", "webg2"];
        for (let i = 0; i < contextIds.length; i++) {
          let gl = app.view.getContext(contextIds[i]);
          if (gl) {
            const buf = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, buf);
            const numAttributes = gl.getParameter(gl.MAX_VERTEX_ATTRIBS);
            for (let attrib = 0; attrib < numAttributes; ++attrib) {
              gl.vertexAttribPointer(attrib, 1, gl.FLOAT, false, 0, 0);
            }

            const numTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
            for (let unit = 0; unit < numTextureUnits; ++unit) {
              gl.activeTexture(gl.TEXTURE0 + unit);
              gl.bindTexture(gl.TEXTURE_2D, null);
              gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
            }

            gl.canvas.width = 1;
            gl.canvas.height = 1;

            let ext = gl.getExtension("WEBGL_lose_context");
            if (ext) {
              ext.loseContext();
            }
          }
        }

        app.destroy(true);

        PIXI.utils.clearTextureCache();
        PIXI.utils.destroyTextureCache();

        //destroy event listeners
        for (let i = 0; i < this.unsubscribeEvents.length; i++) {
          this.unsubscribeEvents[i]();
        }
      };

      //waiting for response of the api
      const stateWaiting = () => {
        if (api_result_last_spin !== null) {
          state = stateCalculate;
        }
      };

      const stateCalculate = () => {
        const vars = current_wheel_var();
        const wheel = current_wheel();

        //find out field from id
        const newest_index = Object.keys(api_result_last_spin).length;
        let calcwinnerId = api_result_last_spin[newest_index].field_id;
        let futureWinnerFieldNr = calcwinnerId - past_field_counter;

        //win amount
        calcWinnerAmount = api_result_last_spin[newest_index].value;

        //arrow field. To to next ring next time
        if (api_result_last_spin[newest_index].value == null) {
          nextWheelLevel = current_wheel_level + 1;
          automatic_restart = true;

          //Jackpot
          if (current_wheel_level === 3) {
            this.handleWheelSpin();
            automatic_restart = false;
          }
        }

        //calculate array index by given degree
        let singleDegree = 360 / vars.length;

        //target degree
        nextHoldpoint = singleDegree * futureWinnerFieldNr;
        nextHoldpoint += (singleDegree / 2) * wheel.direction;
        nextHoldpoint = nextHoldpoint % 360;
        nextHoldpoint = Math.round(nextHoldpoint) * wheel.direction;

        // console.log("singleDegree", singleDegree);
        // console.log("futureWinnerFieldNr", futureWinnerFieldNr);
        // console.log("nextHoldpoint", nextHoldpoint);

        state = stateRunning;
      };

      //running state. waiting for pressing stop and enter slowing state
      const stateRunning = () => {
        if (stopPressed) {
          if (
            Math.round(wheelCurrentPosition / 10) * 10 ===
            Math.round(nextHoldpoint / 10) * 10
          ) {
            app.ticker.remove(animationWheelRotation);
            this.stopSound(sound_main);
            slowDownWheel();
            state = stateSlowing;
          }
        }
      };

      //wheel becomes slower
      const stateSlowing = () => {};

      //stop wheel
      const stateStop = () => {
        if (nextWheelLevel === 4) {
          calcWinnerAmount = jackpotAmount;
          automatic_restart = false;
          state = statePrepareEnd;
        } else {
          state = stateReady;
        }
      };

      const statePrepareEnd = () => {
        currentSpeed = currentSpeedBase;
        nextHoldpoint = null;
        automatic_restart = false;
        this.endKingwheel(calcWinnerAmount);
        state = stateEnd;
      };

      const stateEnd = () => {
        // console.log("STATE END");
      };

      //reset settings
      const resetSettings = (callback) => {
        //reset speed to base speed
        currentSpeed = currentSpeedBase;
        nextHoldpoint = null;
        stopPressed = false;
        api_result_last_spin = null;

        //set automatic stop in 10 seconds
        automaticStopDate = new Date();
        automaticStopDate.setSeconds(automaticStopDate.getSeconds() + 10);
        automaticStopDate = automaticStopDate.getTime();

        if (callback) {
          callback();
        }
      };

      const getCurrentWheelData = (callback) => {
        if (nextWheelLevel) {
          current_wheel_level = nextWheelLevel;
        } else {
          if (
            api_result_last_spin === null ||
            api_result_last_spin === undefined
          ) {
            //no past spins, this will be the first spin
            current_wheel_level = 1;
          } else {
            //count past spin and the current level
            current_wheel_level = Object.keys(api_result_last_spin).length + 1;
          }

          if (testing) {
            current_wheel_level = testlevel++;
          }
        }

        //set active wheel by reference
        past_field_counter = 0;
        switch (current_wheel_level) {
          case 1:
            current_wheel = function () {
              return container_ring_outer;
            };
            current_wheel_var = function () {
              return var_ring_outer;
            };

            break;
          case 2:
            current_wheel = function () {
              return container_ring_middle;
            };

            current_wheel_var = function () {
              return var_ring_middle;
            };
            past_field_counter += var_ring_outer.length;
            break;
          case 3:
            current_wheel = function () {
              return container_ring_inner;
            };

            current_wheel_var = function () {
              return var_ring_inner;
            };
            past_field_counter +=
              var_ring_outer.length + var_ring_middle.length;
            break;
        }

        if (callback) {
          callback();
        }
      };

      //create all sprites
      const createSprites = () => {
        const space_top = 55;
        let sprite_obj;

        //SPIN BUTTON
        sprite_obj = new Sprite(resources[url_button_green1].texture);
        sprite_obj.name = "spin-button";
        sprite_obj.width = 426;
        sprite_obj.height = 196;
        sprite_obj.x = 670;
        sprite_obj.y = 184;
        sprite_obj.zIndex = 70;
        sprite_obj.interactive = true;
        sprite_obj.buttonMode = true;
        sprite_obj.on("mousedown", clickWheelStart);
        sprite_obj.on("touchstart", clickWheelStart);
        app.stage.addChild(sprite_obj);
        spin_button = sprite_obj;

        //spin button text
        let text = new PIXI.Text(this.$t("kingswheel.spin").toUpperCase(), {
          fontSize: 48,
          fill: 0xffffff,
          align: "center",
          fontFamily: "Ubuntu-Bold",
        });
        text.anchor.set(0.5, 0.5);
        text.resolution = 4;
        text.x = 160;
        text.y = 103;
        text.zIndex = 71;
        spin_button.addChild(text);

        //wheel icon
        sprite_obj = new Sprite(resources[url_wheel_icon].texture);
        sprite_obj.name = "spin-wheel-icon";
        sprite_obj.width = 105;
        sprite_obj.height = 105;
        sprite_obj.x = 1010;
        sprite_obj.y = 295;
        sprite_obj.zIndex = 72;
        sprite_obj.anchor.set(0.5, 0.5);
        sprite_obj.interactive = true;
        sprite_obj.buttonMode = true;
        sprite_obj.on("mousedown", clickWheelStart);
        sprite_obj.on("touchstart", clickWheelStart);
        app.stage.addChild(sprite_obj);
        wheel_icon = sprite_obj;

        //spin arrows
        for (let i = 0; i < 3; i++) {
          sprite_obj = new Sprite(resources[url_button_arrow_green].texture);
          sprite_obj.name = "spin-button";
          sprite_obj.width = 69;
          sprite_obj.height = 89;
          sprite_obj.x = 625 - i * 52;
          sprite_obj.y = 245;
          sprite_obj.zIndex = 70;
          sprite_obj.alpha = 0;
          sprite_obj.interactive = true;
          sprite_obj.buttonMode = true;
          sprite_obj.on("mousedown", clickWheelStart);
          sprite_obj.on("touchstart", clickWheelStart);
          app.stage.addChild(sprite_obj);
          arrows.push(sprite_obj);
        }

        //STOPPER
        sprite_obj = new Sprite(resources[url_stopper].texture);
        sprite_obj.name = "stopper";
        sprite_obj.width = 62;
        sprite_obj.height = 59;
        sprite_obj.x = 208;
        sprite_obj.y = 40;
        sprite_obj.zIndex = 70;
        app.stage.addChild(sprite_obj);

        //WIN FRAME
        sprite_obj = new Sprite(resources[url_win_frame].texture);
        sprite_obj.name = "win-frame";
        sprite_obj.width = 157;
        sprite_obj.height = 371;
        sprite_obj.x = 162;
        sprite_obj.y = 0;
        sprite_obj.zIndex = 70;
        app.stage.addChild(sprite_obj);

        //BORDER RING
        sprite_obj = new Sprite(resources[url_ring_border].texture);
        sprite_obj.name = "ring_border";
        sprite_obj.width = 480;
        sprite_obj.height = 480;
        sprite_obj.x = 0;
        sprite_obj.y = space_top;
        sprite_obj.zIndex = 10;
        app.stage.addChild(sprite_obj);

        //OUTER RING
        const outer_ring_width = 406;
        const outer_ring_height = 406;
        let container = new PIXI.Container();
        container.name = "container_ring_outer";
        container.width = outer_ring_width;
        container.height = outer_ring_height;
        container.x = 240;
        container.y = space_top + 240;
        container.zIndex = 20;
        container.direction = -1;
        container.pivot.set(outer_ring_width / 2, outer_ring_height / 2);
        app.stage.addChild(container);
        container_ring_outer = container;

        sprite_obj = new Sprite(resources[url_ring_outer].texture);
        sprite_obj.name = "ring_outer";
        sprite_obj.width = 420;
        sprite_obj.height = 420;
        sprite_obj.x = 30;
        sprite_obj.y = space_top + 30;
        sprite_obj.zIndex = 19;
        app.stage.addChild(sprite_obj);
        ring_outer = sprite_obj;

        sprite_obj = new Sprite(resources[url_spin_ring_outer].texture);
        sprite_obj.name = "ring_spin_outer";
        sprite_obj.width = outer_ring_width;
        sprite_obj.height = outer_ring_height;
        sprite_obj.x = 0;
        sprite_obj.y = 0;
        sprite_obj.zIndex = 21;
        container_ring_outer.addChild(sprite_obj);

        //calculate positions outer ring
        for (let i = 0; i < var_ring_outer.length; i++) {
          const field_amount = var_ring_outer.length;
          const degree_per_field = 360 / field_amount;
          const container_radius = (ring_outer.width / 2) * 0.9;
          const space = 14;

          let pos_x,
            pos_y,
            angle = 0;

          let deg2Rad = 0.01745329;
          angle =
            (degree_per_field * ((field_amount / 2 - 1 - i) % field_amount) +
              degree_per_field / 2) *
            deg2Rad;
          pos_x = Math.sin(angle) * container_radius + container_radius;
          pos_y = Math.cos(angle) * container_radius + container_radius;

          let number = var_ring_outer[i].value;
          if (number === null) {
            sprite_obj = new Sprite(resources[url_ring_arrow].texture);
            sprite_obj.name = "ring_arrow";
            sprite_obj.width = 44;
            sprite_obj.height = 38;
            sprite_obj.x = pos_x + space;
            sprite_obj.y = pos_y + space;
            sprite_obj.angle = degree_per_field + i * degree_per_field - 1;
            sprite_obj.vx = 0;
            sprite_obj.vy = 0;
            sprite_obj.anchor.set(0.55, 0.35);
            sprite_obj.zIndex = 50;
            container_ring_outer.addChild(sprite_obj);
            continue;
          }

          let text = new PIXI.BitmapText(reformatNumber(number).toString(), {
            fontName: "kanitblack",
            fontSize: 29,
            align: "center",
          });
          text.name = var_ring_outer.id;
          text.x = pos_x + space;
          text.y = pos_y + space;
          text.angle = degree_per_field / 2 + i * degree_per_field;
          text.zIndex = 30;
          text.anchor.set(0.5, 0.5);
          text.resolution = 3;
          container_ring_outer.addChild(text);

          let number_end = reformatNumberEnding(number).toString();
          let text2 = new PIXI.BitmapText(number_end, {
            fontName: "kanitblack",
            fontSize: 12,
            align: "center",
          });

          text2.name = var_ring_outer.id;
          text2.y = 20;
          text2.zIndex = 30;
          text2.anchor.set(0.5, 0.5);
          text2.resolution = 3;
          text.addChild(text2);
        }

        // MIDDLE RING
        const middle_ring_width = 308;
        const middle_ring_height = 308;
        container = new PIXI.Container();
        container.name = "container_ring_middle";
        container.width = middle_ring_width;
        container.height = middle_ring_height;
        container.x = 240;
        container.y = space_top + 240;
        container.zIndex = 22;
        container.pivot.set(middle_ring_width / 2, middle_ring_height / 2);
        container.direction = -1;
        app.stage.addChild(container);
        container_ring_middle = container;

        sprite_obj = new Sprite(resources[url_ring_middle].texture);
        sprite_obj.name = "ring_middle";
        sprite_obj.width = 318;
        sprite_obj.height = 317;
        sprite_obj.x = 83;
        sprite_obj.y = space_top + 83;
        sprite_obj.zIndex = 21;
        app.stage.addChild(sprite_obj);
        ring_middle = sprite_obj;

        sprite_obj = new Sprite(resources[url_spin_ring_middle].texture);
        sprite_obj.name = "ring_spin_middle";
        sprite_obj.width = middle_ring_width;
        sprite_obj.height = middle_ring_height;
        sprite_obj.x = 0;
        sprite_obj.y = 0;
        sprite_obj.zIndex = 22;
        container_ring_middle.addChild(sprite_obj);

        //calculate positions middle ring
        for (let i = 0; i < var_ring_middle.length; i++) {
          const field_amount = var_ring_middle.length;
          const degree_per_field = 360 / field_amount;
          const container_radius = (ring_middle.width / 2) * 0.83;
          const space = 23;

          let pos_x,
            pos_y,
            angle = 0;

          let deg2Rad = 0.01745329;
          angle =
            (degree_per_field * ((field_amount / 2 - 1 - i) % field_amount) +
              degree_per_field / 2) *
            deg2Rad;
          pos_x = Math.sin(angle) * container_radius + container_radius;
          pos_y = Math.cos(angle) * container_radius + container_radius;

          let number = var_ring_middle[i].value;
          if (number === null) {
            sprite_obj = new Sprite(resources[url_ring_arrow].texture);
            sprite_obj.name = "ring_arrow";
            sprite_obj.width = 44;
            sprite_obj.height = 38;
            sprite_obj.x = pos_x + space;
            sprite_obj.y = pos_y + space;
            sprite_obj.angle = degree_per_field + i * degree_per_field - 4;
            sprite_obj.vx = 0;
            sprite_obj.vy = 0;
            sprite_obj.anchor.set(0.52, 0.4);
            sprite_obj.zIndex = 50;
            container_ring_middle.addChild(sprite_obj);
            continue;
          }

          let text = new PIXI.BitmapText(reformatNumber(number).toString(), {
            fontName: "kanitwhite",
            fontSize: 32,
            align: "center",
          });

          text.name = var_ring_middle.id;
          text.x = pos_x + space;
          text.y = pos_y + space;
          text.angle = degree_per_field / 2 + i * degree_per_field;
          text.zIndex = 30;
          text.anchor.set(0.5, 0.5);
          text.resolution = 3;
          container_ring_middle.addChild(text);

          let number_end = reformatNumberEnding(number).toString();
          let text2 = new PIXI.BitmapText(number_end, {
            fontName: "kanitwhite",
            fontSize: 13,
            align: "center",
          });

          text2.name = var_ring_middle.id;
          text2.y = 20;
          text2.zIndex = 30;
          text2.anchor.set(0.5, 0.5);
          text2.resolution = 3;
          text.addChild(text2);
        }

        // INNER RING
        const inner_ring_width = 188;
        const inner_ring_height = 188;
        container = new PIXI.Container();
        container.name = "container_ring_inner";
        container.width = inner_ring_width;
        container.height = inner_ring_height;
        container.x = 244;
        container.y = space_top + 244;
        container.zIndex = 32;
        container.pivot.set(inner_ring_width / 2, inner_ring_height / 2);
        container.direction = -1;
        app.stage.addChild(container);
        container_ring_inner = container;

        sprite_obj = new Sprite(resources[url_ring_inner].texture);
        sprite_obj.name = "url_ring_inner";
        sprite_obj.width = 194;
        sprite_obj.height = 194;
        sprite_obj.x = 144;
        sprite_obj.y = space_top + 144;
        sprite_obj.zIndex = 31;
        app.stage.addChild(sprite_obj);
        ring_inner = sprite_obj;

        sprite_obj = new Sprite(resources[url_spin_ring_inner].texture);
        sprite_obj.name = "url_spin_ring_inner";
        sprite_obj.width = inner_ring_width;
        sprite_obj.height = inner_ring_height;
        sprite_obj.x = 0;
        sprite_obj.y = 0;
        sprite_obj.zIndex = 32;
        container_ring_inner.addChild(sprite_obj);

        //calculate positions inner ring
        for (let i = 0; i < var_ring_inner.length; i++) {
          const field_amount = var_ring_inner.length;
          const degree_per_field = 360 / field_amount;
          const container_radius = 77;
          const space = 15;

          let pos_x,
            pos_y,
            angle = 0;

          let deg2Rad = 0.01745329;
          angle =
            (degree_per_field * ((field_amount / 2 - 1 - i) % field_amount) +
              degree_per_field / 2) *
            deg2Rad;
          pos_x = Math.sin(angle) * container_radius + container_radius;
          pos_y = Math.cos(angle) * container_radius + container_radius;

          let number = var_ring_inner[i].value;
          if (number === null) {
            sprite_obj = new Sprite(resources[url_ring_arrow].texture);
            sprite_obj.name = "ring_arrow";
            sprite_obj.width = 44;
            sprite_obj.height = 38;
            sprite_obj.x = pos_x + space;
            sprite_obj.y = pos_y + space;
            sprite_obj.angle = degree_per_field + i * degree_per_field - 9;
            sprite_obj.anchor.set(0.5, 0.35);
            sprite_obj.zIndex = 50;
            container_ring_inner.addChild(sprite_obj);
            continue;
          }

          let text = new PIXI.BitmapText(reformatNumber(number).toString(), {
            fontName: "kanitblack",
            fontSize: 29,
            align: "center",
          });

          text.name = var_ring_inner.id;
          text.x = pos_x + space;
          text.y = pos_y + space;
          text.angle = degree_per_field / 2 + i * degree_per_field;
          text.zIndex = 30;
          text.anchor.set(0.5, 0.5);
          text.resolution = 3;
          container_ring_inner.addChild(text);

          let number_end = reformatNumberEnding(number).toString();
          let text2 = new PIXI.BitmapText(number_end, {
            fontName: "kanitblack",
            fontSize: 12,
            align: "center",
          });

          text2.name = var_ring_inner.id;
          text2.y = 20;
          text2.zIndex = 30;
          text2.anchor.set(0.5, 0.5);
          text2.resolution = 3;
          text.addChild(text2);
        }

        //Jackpot icon
        sprite_obj = new Sprite(resources[url_ring_jack].texture);
        sprite_obj.name = "ring_jack";
        sprite_obj.width = 89;
        sprite_obj.height = 89;
        sprite_obj.x = 197;
        sprite_obj.y = space_top + 197;
        sprite_obj.zIndex = 50;
        sprite_obj.interactive = true;
        sprite_obj.buttonMode = true;
        sprite_obj.on("mousedown", clickWheelStart);
        sprite_obj.on("touchstart", clickWheelStart);
        app.stage.addChild(sprite_obj);

        //sort sprites by z-index. Pixijs does not manage it correctly
        const updateLayersOrder = () => {
          app.stage.children.sort(function (a, b) {
            a.zIndex = a.zIndex || 0;
            b.zIndex = b.zIndex || 0;
            return a.zIndex - b.zIndex;
          });
        };
        updateLayersOrder();
      };

      //shortens the numbers to fit in the wheel
      const reformatNumber = (number) => {
        if (number.toString().length >= 4 && number.toString().length <= 6) {
          return number / 1000;
        }

        if (number.toString().length >= 7) {
          return number / 1000000;
        }
      };

      //get MIO or K
      const reformatNumberEnding = (number) => {
        if (number.toString().length >= 4 && number.toString().length <= 6) {
          return this.$t("kingswheel.abbr_thousand");
        }

        if (number.toString().length >= 7) {
          return this.$t("kingswheel.abbr_million");
        }
      };

      //slow down wheel and enter stop state
      const slowDownWheel = () => {
        const wheel = current_wheel();
        const timeline = this.gsap.timeline();
        const rotation = 360 * 3 - (nextHoldpoint - wheelCurrentPosition);
        timeline.to(wheel, 3, {
          angle: "-=" + rotation,
          onStart: () => {
            this.playSoundEffect(sound_end, false, 0.5);
          },
          onComplete: () => {
            this.stopSound(sound_end);
            state = stateStop;
          },
        });
      };
    },
    // END CREATE WHEEL

    handleWheelSpin: async function () {
      return new Promise((resolve, reject) => {
        kingspathAPI
          .spinKingspathWheel()
          .then((res) => {
            // console.log("SPIN RESULT", res);
            if (res) {
              resolve(res.spin.data);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    handleIsNextMoveAvailable: function () {
      return new Promise((resolve) => {
        kingspathAPI
          .fetchKingspathWheel()
          .then((res) => {
            resolve(res !== undefined);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    endKingwheel: function (amount) {
      this.$store.dispatch("user/setDelayedUpdate", false);

      //show win modal
      this.winAmount = amount;
      this.$bvModal.hide("modal-kingswheel");
      this.$bvModal.show("modal-kingswheel-reward-win");

      setTimeout(() => {
        this.$bvModal.hide("modal-kingswheel");
      }, 2000);

      setTimeout(() => {
        this.$store.commit("kingspath/setKingspathNeedUpdate", true);
      }, 3000);
    },

    handleNeedsUpdate: function () {
      return this.$store.getters["kingspath/getKingwheelNeedUpdate"];
    },
  },
};
</script>

<style lang="scss">
#modal-kingswheel {
  $modal-width: 1274px;
  $modal-height: 703px;

  .modal-dialog {
    max-width: $modal-width;
    height: $modal-height;
    padding: 0 !important;

    @media (max-height: 750px), (max-width: 1300px) {
      transform: scale(0.8);
      top: -80px;
    }
    @media (max-width: 1200px) {
      transform: scale(0.7);
      min-width: 1274px;
      left: -100px;
    }
  }

  .modal-content {
    height: 100%;
    background: url(~@/assets/img/kingspath/rad/kleinekrone-kp-overlay-wheel.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .close-button {
    position: absolute;
    right: -11px;
    top: -11px;
    width: 54px;
    height: 54px;
    max-width: none;
    background: url(~@/assets/img/mission/mission-close-button.png) no-repeat;
    background-size: 100% 100%;

    &:hover {
      background-image: url(~@/assets/img/mission/mission-close-button-hover.png);
    }
  }
}
</style>

<style lang="scss" scoped>
#modal-kingswheel {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/mission/mission-close-button-hover.png);
  }

  .headline {
    top: 62px;
    left: 366px;
    width: 424px;
    height: 108px;
  }

  #kingswheel-container {
    #jackpot-gold {
      position: absolute;
      left: 18px;
      bottom: -10px;
      width: 641px;
      height: 278px;
      z-index: 20;
      background: url(~@/assets/img/kingspath/rad/kp-wheel-fg-assets.png)
        no-repeat;
      background-size: 100% 100%;

      .coin-container {
        width: 270px;
        top: 153px;

        .coin-bar {
          width: 100%;
          height: 65px;
        }

        .coin-icon {
          position: absolute;
          top: 23px;
          right: -1px;
          height: 30px;
        }

        .coin-text {
          font-family: Ubuntu-Bold;
          font-size: 34px;
          position: absolute;
          right: 37px;
          top: 12px;
          color: #4de5ff;
        }
      }
    }

    #pixi-kingswheel {
      position: absolute;
      z-index: 5;
      top: -28px;
      left: 99px;

      //@media (max-width: 1200px) {
      //  transform: scale(0.9);
      //  left: 35px;
      //  top: -30px;
      //}
      //
      //@media (max-width: 1100px) {
      //  transform: scale(0.85);
      //  left: -10px;
      //  top: -30px;
      //}

      canvas {
        margin-top: 70px;
      }
    }

    .infotext {
      position: absolute;
      bottom: 47px;
      right: 96px;
      width: 350px;
      text-align: left;
      line-height: 1.2;
      z-index: 30;

      .info-headline {
        font-family: Cinzel-Bold;
        font-size: 36px;
        color: #fdf28d;
        text-transform: uppercase;

        img {
          position: absolute;
          left: -64px;
          top: 5px;
        }
      }
      .text1 {
        font-family: Ubuntu;
        font-size: 24px;
        margin-top: 10px;
      }
      .text2 {
        font-family: Ubuntu-Bold;
        font-size: 28px;
        margin-top: 10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
#modal-kingswheel {
  .header {
    width: 600px;
    top: -80px;
  }

  .kingwheel-spinner {
    top: -120px;
    left: -508px;
    z-index: 10;
  }
}
</style>
