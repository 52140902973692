<template>
  <div class="kingspath-card-action">
    <div class="kingspath-card-action-bg position-relative">
      <template v-if="!mission">
        <template v-if="opened">
          <div class="card-overlay to-center-abs" @click="showBuyModal"></div>

          <DecoButton color-variant="blue" @click="showBuyModal">
            <div class="kingspath-card-action-text">
              <div class="crown-container">
                <div class="crown-amount">
                  {{ config["kingspath.buy.costs"] }}
                </div>
                <div class="crown-img">
                  <img
                    draggable="false"
                    src="@/assets/img/common/crown6.png"
                    class="w-100"
                  />
                </div>
              </div>
            </div>
          </DecoButton>
        </template>
        <template v-else>
          <div
            class="card-overlay to-center-abs"
            @click="$emit('unlock-mission', index)"
          ></div>
          <DecoButton
            color-variant="blue"
            class="button-green-md card-button1 w-100 kingspath-button-closed"
            @click="$emit('unlock-mission', index)"
          >
            <div class="kingspath-card-action-text rotate-button">
              {{ $t("kingspath.popup_choose_quest.button.rotate") }}
            </div>
          </DecoButton>
        </template>
      </template>

      <template v-else>
        <template v-if="mission.status === 'ACTIVE'">
          <!--          already active mission-->
          <div
            class="card-overlay to-center-abs"
            @click="$emit('activate-mission', mission)"
          ></div>
          <DecoButton
            color-variant="green"
            class="button-green-md card-button1 w-100 kingspath-button-open kingspath-button-continue"
            @click="$emit('activate-mission', mission)"
          >
            <div class="kingspath-card-action-text">
              {{ $t("kingspath.popup_choose_quest.button.continue") }}
            </div>
          </DecoButton>
        </template>
        <template v-else-if="!mission.status">
          <!--          just turned card. default active mission-->
          <div
            class="card-overlay to-center-abs kingspath-button-open kingspath-button-start"
            @click="$emit('activate-mission', mission)"
          ></div>
          <DecoButton
            color-variant="blue"
            class="button-blue-md card-button1 w-100"
            @click="$emit('activate-mission', mission)"
          >
            <div class="kingspath-card-action-text">
              {{ $t("kingspath.popup_choose_quest.button.start") }}
            </div>
          </DecoButton>
        </template>
        <template v-else>
          <!--          not active yet-->
          <div
            class="card-overlay to-center-abs kingspath-button-open kingspath-button-start"
            @click="$emit('activate-mission', mission)"
          ></div>
          <DecoButton
            color-variant="blue"
            class="button-blue-md card-button1 w-100"
            @click="$emit('activate-mission', mission)"
          >
            <div class="kingspath-card-action-text">
              {{ $t("kingspath.popup_choose_quest.button.start") }}
            </div>
          </DecoButton>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import DecoButton from "@/components/base/deco-button.vue";

export default {
  name: "KingspathCardButton",
  components: {
    DecoButton,
  },
  props: {
    mission: {
      type: Object,
      default: null,
    },
    opened: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    config: { type: Object, default: null },
  },

  methods: {
    showBuyModal: function () {
      this.$bvModal.show("modal-kingspath-buy-mission");
    },
  },
};
</script>

<style lang="scss">
.card-container {
  .deco-button {
    width: 180px !important;
    height: 36px !important;
    font-family: Ubuntu;
    font-size: 22.5px;

    .deco-gem {
      width: 17px;
      height: 44px;
      top: -6px;

      &.left {
        left: -10px;
      }
      &.right {
        right: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.card-container {
  margin-top: 20px;

  .kingspath-card-action {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;

    .kingspath-card-action-text {
      font-family: Ubuntu;
      color: white;
      font-size: 21px;
      text-transform: uppercase;
      padding-bottom: 3px;

      &.rotate-button {
        color: #4de5ff;
      }
    }

    .crown-container {
      position: relative;
      text-align: center;

      .crown-amount {
        display: inline-block;
        font-size: 21px;
        color: #fff8b7;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ */
          & {
            background: transparent;
            color: rgb(253, 242, 141);
          }
        }
      }

      .crown-img {
        display: inline-block;
        width: 37px;
        position: relative;
        top: -5px;
      }
    }
  }
}

.card-overlay {
  top: -297px;
  width: 200px;
  height: 276px;
  cursor: pointer;
}
</style>
